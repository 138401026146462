import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const ManagersService = {};
ManagersService.getManagers = function (page = 1) {
    return fetch({
        method: "get",
        url: `api/user/getAll?page=${page}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.login = function (data) {
    return fetch({
        url: "api/user/login",
        method: "post",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });
};
ManagersService.getAllControllers = function () {
    return fetch({
        method: "get",
        url: `api/user/getAllControllers`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.createManager = function (data) {
    return fetch({
        method: "post",
        url: `api/user/create`,
        data: data,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.getManager = function (id) {
    return fetch({
        method: "get",
        url: `api/user/getById/${id}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.deleteManager = function (id) {
    return fetch({
        method: "delete",
        url: `api/user/deleteById/${id}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.deleteManagers = function () {
    return fetch({
        method: "delete",
        url: `api/user/deleteAll`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
ManagersService.updateManager = function (id, data) {
    return fetch({
        method: "put",
        url: `api/user/updatePrivileges/${id}`,
        data: data,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
export default ManagersService;
