import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UsersService from "services/UsersService";

export const getUsers = createAsyncThunk(
    "users/getUsers",
    async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
        try {
            const response = await UsersService.getUsers({ page, limit });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
const initialState = {
    users: [],
    loading: false,
    error: null,
};

const ambassadorSlice = createSlice({
    name: "ambassadors",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state, action) => {
            state.loading = false;
        },
        hideError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = action.payload.data;
                state.loading = false;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});
export default ambassadorSlice.reducer;
export const { showLoading, hideLoading, hideError } = ambassadorSlice.actions;
