import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";

const HistoriesService = {};

HistoriesService.getUserCompanyHistory = async ({ companyId, userId, page }) =>
    fetch({
        url: `api/companyHistory/company/${companyId}/getCompanyHistoryForOneUser/${userId}?page=${page}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });

export default HistoriesService;
