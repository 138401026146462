import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";

const UsersService = {};

UsersService.getUsers = async ({ page, limit }) =>
    await fetch({
        url: `api/user/getAll?page=${page}&limit=${limit}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });

export default UsersService;
