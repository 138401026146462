import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const RelationshipService = {}
RelationshipService.getRelationships = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/relationship/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.createRelationshipWithoutService = function (data) {
  return fetch({
    method: "post",
    url: `api/relationship/createWithoutService`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.createRelationshipWithService = function (data) {
  return fetch({
    method: "post",
    url: `api/relationship/createWithService`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.getRelationship = function (id) {
  return fetch({
    method: "get",
    url: `api/relationship/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.deleteRelationship = function (id) {
  return fetch({
    method: "delete",
    url: `api/relationship/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.updateRelationship = function (id, data) {
  return fetch({
    method: "put",
    url: `api/relationship/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.deleteRelationships = function () {
  return fetch({
    method: "delete",
    url: `api/relationship/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.validateRelationship = function (id) {
  return fetch({
    method: "post",
    url: `api/relationship/validate/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
RelationshipService.refuseRelationship = function (id, argument) {
  return fetch({
    method: "post",
    url: `api/relationship/refused/${id}`,
    data: {
      argument: argument,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
export default RelationshipService
