import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const ServiceService = {};
ServiceService.getServices = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/service/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.getServicesByCompany = function (page = 1) {
  return fetch({
    method: "get",
    url: `/api/service/getAllServicesForCompany?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.createService = function (data) {
  return fetch({
    method: "post",
    url: `api/service/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.getService = function (id) {
  return fetch({
    method: "get",
    url: `api/service/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.deleteService = function (id) {
  return fetch({
    method: "delete",
    url: `api/service/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.updateService = function (id, data) {
  return fetch({
    method: "put",
    url: `api/service/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ServiceService.deleteServices = function () {
  return fetch({
    method: "delete",
    url: `api/service/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default ServiceService;
