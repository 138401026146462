import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DomainService from "services/DomainService";

export const getDomains = createAsyncThunk(
  "domains/getDomains",
  async (_, { rejectWithValue }) => {
    try {
      const response = await DomainService.getDomains();
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAllDomain = createAsyncThunk(
  "domains/getAllDomain",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await DomainService.getAllDomains(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createDomain = createAsyncThunk(
  "domain/createDomain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DomainService.createDomain(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateDomain = createAsyncThunk(
  "domains/updateDomain",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await DomainService.updateDomain(id, data);
      return { id, updatedDomain: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteDomain = createAsyncThunk(
  "domains/deleteDomain",
  async (id, { rejectWithValue }) => {
    try {
      await DomainService.deleteDomain(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneDomain = createAsyncThunk(
  "domains/fetchOneDomain",
  async (id, { rejectWithValue }) => {
    try {
      const response = await DomainService.getDomain(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  domains: [],
  allDomains: [],
  domain: {},
  loading: false,
  error: null,
};

const domainSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch domains
      .addCase(getDomains.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        state.loading = false;
        state.domains = action.payload;
      })
      .addCase(getDomains.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Fetch all domains
      .addCase(getAllDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.totalItems = action.payload.totalItems;
        state.allDomains = action.payload.data;
      })
      .addCase(getAllDomain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create Domain
      .addCase(createDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDomain.fulfilled, (state, action) => {
        state.domains.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createDomain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update domain
      .addCase(updateDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDomain.fulfilled, (state, action) => {
        const { id, updatedDomain } = action.payload;
        const index = state.domains.findIndex((domain) => domain.id === id);
        if (index !== -1) {
          state.domains[index] = {
            ...state.domains[index],
            ...updatedDomain,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateDomain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete domain
      .addCase(deleteDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        const domainId = action.payload;
        state.domains = state.domains.filter(
          (domain) => domain._id !== domainId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.domain = action.payload;
        state.error = null;
      })
      .addCase(fetchOneDomain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default domainSlice.reducer;
export const { showLoading, hideLoading, hideError } = domainSlice.actions;
