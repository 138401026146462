import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HistoriesService from "services/HistoriesService";

const initialState = {
    history: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null,
};

export const getUserCompanyHistory = createAsyncThunk(
    "history/getUserCompanyHistory",
    async ({ companyId, userId, page }, { rejectWithValue }) => {
        try {
            const response = await HistoriesService.getUserCompanyHistory({
                companyId,
                userId,
                page,
            });
            return {
                history: response.data.data,
                currentPage: response.data.page,
                totalPages: response.data.pages,
            };
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);

const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state, action) => {
            state.loading = false;
        },
        hideError: (state, action) => {
            state.error = null;
        },
        resetPage: (state) => {
            state.currentPage = 1;
            state.totalPages = 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserCompanyHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserCompanyHistory.fulfilled, (state, action) => {
                state.history = action.payload.history;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.loading = false;
            })
            .addCase(getUserCompanyHistory.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});
export default historySlice.reducer;
export const { showLoading, hideLoading, hideError, resetPage } =
    historySlice.actions;
