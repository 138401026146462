import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import DashboardService from "services/DashboardService"

export const getNumberOfValidatedAmbassadors = createAsyncThunk(
  "dashboard/getNumberOfValidatedAmbassadors",
  async (companyId) => {
    const response = await DashboardService.getNumberOfValidatedAmbassadors(
      companyId
    )
    return response.data.data
  }
)

export const getNumberOfRefusedAmbassadors = createAsyncThunk(
  "dashboard/getNumberOfRefusedAmbassadors",
  async (companyId) => {
    const response = await DashboardService.getNumberOfRefusedAmbassadors(
      companyId
    )
    return response.data.data
  }
)

export const getNumberOfAquiredClients = createAsyncThunk(
  "dashboard/getNumberOfAquiredClients",
  async (companyId) => {
    const response = await DashboardService.getNumberOfAquiredClients(companyId)
    return response.data.data
  }
)

export const getAllRelations = createAsyncThunk(
  "dashboard/getAllRelations",
  async (companyId) => {
    const response = await DashboardService.getAllRelations(companyId)
    return response.data.data
  }
)

export const getNumberOfCommercials = createAsyncThunk(
  "dashboard/getNumberOfCommercials",
  async (companyId) => {
    const response = await DashboardService.getNumberOfCommercials(companyId)
    return response.data.data
  }
)

export const getRelationsSuccessByMonth = createAsyncThunk(
  "dashboard/getRelationsSuccessByMonth",
  async ({ companyId, year = new Date(Date.now()).getFullYear() }) => {
    const response = await DashboardService.getRelationsSuccessByMonth({
      companyId,
      year,
    })
    return response.data.data
  }
)

export const getTopServices = createAsyncThunk(
  "dashboard/getTopServices",
  async (companyId) => {
    const response = await DashboardService.getTopServices(companyId)
    return response.data.data
  }
)
export const getImageOfCompany = createAsyncThunk(
  "dashboard/getIamge",
  async (companyId) => {
    const response = await DashboardService.getImage(companyId)
    return response.data
  }
)

const initialState = {
  validatedAmbassadors: 0,
  refusedAmbassadors: 0,
  acquiredClients: 0,
  allRelations: 0,
  commercialsCount: 0,
  relationsSuccessByMonth: [],
  topServices: [],
  loading: false,
  error: null,
  image: null,
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNumberOfValidatedAmbassadors.pending, (state) => {
        state.loading = true
      })
      .addCase(getNumberOfValidatedAmbassadors.fulfilled, (state, action) => {
        state.loading = false
        state.validatedAmbassadors = action.payload
      })
      .addCase(getNumberOfValidatedAmbassadors.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getNumberOfRefusedAmbassadors.pending, (state) => {
        state.loading = true
      })
      .addCase(getNumberOfRefusedAmbassadors.fulfilled, (state, action) => {
        state.loading = false
        state.refusedAmbassadors = action.payload
      })
      .addCase(getNumberOfRefusedAmbassadors.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getNumberOfAquiredClients.pending, (state) => {
        state.loading = true
      })
      .addCase(getNumberOfAquiredClients.fulfilled, (state, action) => {
        state.loading = false
        state.acquiredClients = action.payload
      })
      .addCase(getNumberOfAquiredClients.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getAllRelations.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllRelations.fulfilled, (state, action) => {
        state.loading = false
        state.allRelations = action.payload
      })
      .addCase(getAllRelations.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getNumberOfCommercials.pending, (state) => {
        state.loading = true
      })
      .addCase(getNumberOfCommercials.fulfilled, (state, action) => {
        state.loading = false
        state.commercialsCount = action.payload
      })
      .addCase(getNumberOfCommercials.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getRelationsSuccessByMonth.pending, (state) => {
        state.loading = true
      })
      .addCase(getRelationsSuccessByMonth.fulfilled, (state, action) => {
        state.loading = false
        state.relationsSuccessByMonth = action.payload
      })
      .addCase(getRelationsSuccessByMonth.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getTopServices.pending, (state) => {
        state.loading = true
      })
      .addCase(getTopServices.fulfilled, (state, action) => {
        state.loading = false
        state.topServices = action.payload
      })
      .addCase(getTopServices.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getImageOfCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getImageOfCompany.fulfilled, (state, action) => {
        state.loading = false
        state.image = action.payload.image
      })
      .addCase(getImageOfCompany.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})
export default dashboardSlice.reducer
export const { showLoading, hideLoading, hideError } = dashboardSlice.actions
