import { createSlice } from "@reduxjs/toolkit";
import { THEME_CONFIG } from "configs/AppConfig";

const savedTheme = JSON.parse(localStorage.getItem("theme"));

if (!savedTheme) {
    localStorage.setItem("theme", JSON.stringify(THEME_CONFIG));
}

export const initialState = {
    ...THEME_CONFIG,
    ...savedTheme,
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        toggleCollapsedNav: (state, action) => {
            state.navCollapsed = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onNavStyleChange: (state, action) => {
            state.sideNavTheme = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onLocaleChange: (state, action) => {
            state.locale = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onNavTypeChange: (state, action) => {
            state.navType = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onTopNavColorChange: (state, action) => {
            state.topNavColor = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onHeaderNavColorChange: (state, action) => {
            state.headerNavColor = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onMobileNavToggle: (state, action) => {
            state.mobileNav = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onSwitchTheme: (state, action) => {
            state.currentTheme = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onDirectionChange: (state, action) => {
            state.direction = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
        onBlankLayout: (state, action) => {
            state.blankLayout = action.payload;
            localStorage.setItem("theme", JSON.stringify(state));
        },
    },
});

export const {
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange,
    onBlankLayout,
} = themeSlice.actions;

export default themeSlice.reducer;
