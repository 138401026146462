import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AmbassadorService from "services/AmbassadorService";

export const getAllAmbassadors = createAsyncThunk(
  "Ambassadors/getAllAmbassadors",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.getAmbassadors(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAllAmbassadorsRequest = createAsyncThunk(
  "Ambassadors/getAllAmbassadorsRequest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.getAmbassadorsRequests();
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const deleteAmbassador = createAsyncThunk(
  "Ambassadors/deleteAmbassador",
  async ({ companyId, ambassadorId }, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.deleteAmbassador(
        companyId,
        ambassadorId
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAmbassador = createAsyncThunk(
  "Ambassadors/getAmbassador",
  async ({ companyId, ambassadorId }, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.getAmbassador(
        companyId,
        ambassadorId
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createAmbassador = createAsyncThunk(
  "Ambassadors/createAmbassador",
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.createAmbassador(
        companyId,
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const validateAmbassador = createAsyncThunk(
  "ambassadors/validateAmbassador",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.validateAmbassador(id);
      return { id, updatedAmbassador: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const refuseAmbassador = createAsyncThunk(
  "ambassadors/refuseAmbassador",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AmbassadorService.refuseAmbassador(id, data);
      return { id, updatedAmbassador: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  ambassadors: [],
  ambassadorsRequests: [],
  ambassador: {},
  loading: false,
  error: null,
};

const ambassadorSlice = createSlice({
  name: "ambassadors",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch ambassadors
      .addCase(getAllAmbassadors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAmbassadors.fulfilled, (state, action) => {
        state.loading = false;
        state.ambassadors = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllAmbassadors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch ambassadors
      .addCase(getAllAmbassadorsRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAmbassadorsRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.ambassadorsRequests = action.payload;
      })
      .addCase(getAllAmbassadorsRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create Ambassador
      .addCase(createAmbassador.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAmbassador.fulfilled, (state, action) => {
        state.ambassadors.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createAmbassador.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update ambassador
      // .addCase(updateAmbassador.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updateAmbassador.fulfilled, (state, action) => {
      //   const { ambassadorId, updatedJob } = action.payload;
      //   const index = state.ambassadors.findIndex(
      //     (ambassador) => ambassador.id === ambassadorId
      //   );
      //   if (index !== -1) {
      //     state.ambassadors[index] = {
      //       ...state.ambassadors[index],
      //       ...updatedJob,
      //     };
      //   }
      //   state.loading = false;
      // })
      // .addCase(updateAmbassador.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // Delete ambassador
      .addCase(deleteAmbassador.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAmbassador.fulfilled, (state, action) => {
        const ambassadorId = action.payload;
        state.ambassadors = state.ambassadors.filter(
          (ambassador) => ambassador._id !== ambassadorId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteAmbassador.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAmbassador.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAmbassador.fulfilled, (state, action) => {
        state.loading = false;
        state.ambassador = action.payload;
        state.error = null;
      })
      .addCase(getAmbassador.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validateAmbassador.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateAmbassador.fulfilled, (state, action) => {
        const { id, updatedAmbassador } = action.payload;
        const index = state.ambassadors.findIndex(
          (ambassador) => ambassador.id === id
        );
        if (index !== -1) {
          state.ambassadors[index] = {
            ...state.ambassadors[index],
            ...updatedAmbassador,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(validateAmbassador.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refuseAmbassador.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refuseAmbassador.fulfilled, (state, action) => {
        const { id, updatedAmbassador } = action.payload;
        const index = state.ambassadors.findIndex(
          (ambassador) => ambassador.id === id
        );
        if (index !== -1) {
          state.ambassadors[index] = {
            ...state.ambassadors[index],
            ...updatedAmbassador,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(refuseAmbassador.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default ambassadorSlice.reducer;
export const { showLoading, hideLoading, hideError } = ambassadorSlice.actions;
