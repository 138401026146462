import { combineReducers } from "redux"
import ambassadors from "./slices/ambassadorSlice"
import auth from "./slices/authSlice"
import commercials from "./slices/commercialSlice"
import dashboard from "./slices/dashboardSlice"
import domains from "./slices/domainSlice"
import history from "./slices/historySlice"
import invitations from "./slices/invitationSlice"
import managers from "./slices/managersSlice"
import messages from "./slices/messageSlice"
import promotions from "./slices/promotionSlice"
import relationships from "./slices/relationshipSlice"
import services from "./slices/serviceSlice"
import theme from "./slices/themeSlice"
import users from "./slices/usersSlice.js"
import clientCompany from "./slices/clientCompanySlice"
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    domains,
    services,
    commercials,
    ambassadors,
    relationships,
    messages,
    managers,
    promotions,
    invitations,
    dashboard,
    history,
    users,
    clientCompany,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
