import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import InvitationService from "services/InvitationService";

export const getAllInvitations = createAsyncThunk(
  "Invitations/getAllInvitations",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await InvitationService.getInvitations(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const deleteInvitation = createAsyncThunk(
  "Invitations/deleteInvitation",
  async ({ invitationId }, { rejectWithValue }) => {
    try {
      const response = await InvitationService.deleteInvitation(invitationId);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getInvitation = createAsyncThunk(
  "Invitations/getInvitation",
  async ({ invitationId }, { rejectWithValue }) => {
    try {
      const response = await InvitationService.getInvitation(invitationId);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const sendInvitation = createAsyncThunk(
  "Invitations/sendInvitation",
  async ({ invitationId }, { rejectWithValue }) => {
    try {
      const response = await InvitationService.sendInvitation(invitationId);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

const initialState = {
  invitations: [],
  invitation: {},
  loading: false,
  error: null,
};

const invitationSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch invitations
      .addCase(getAllInvitations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllInvitations.fulfilled, (state, action) => {
        state.loading = false;
        state.invitations = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllInvitations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete invitation
      .addCase(deleteInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteInvitation.fulfilled, (state, action) => {
        const invitationId = action.payload;
        state.invitations = state.invitations.filter(
          (invitation) => invitation._id !== invitationId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.invitation = action.payload;
        state.error = null;
      })
      .addCase(getInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default invitationSlice.reducer;
export const { showLoading, hideLoading, hideError } = invitationSlice.actions;
