import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PromotionService from "services/PromotionService";

export const getAllPromotions = createAsyncThunk(
  "promotions/getAllPromotions",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await PromotionService.getPromotions(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAllPromotionsByCompany = createAsyncThunk(
  "promotions/getAllPromotionsByCompany",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await PromotionService.getPromotionsByCompany(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createPromotion = createAsyncThunk(
  "promotion/createPromotion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PromotionService.createPromotion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updatePromotion = createAsyncThunk(
  "promotions/updatePromotion",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await PromotionService.updatePromotion(id, data);
      return { id, updatedPromotion: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deletePromotion = createAsyncThunk(
  "promotions/deletePromotion",
  async (id, { rejectWithValue }) => {
    try {
      await PromotionService.deletePromotion(id);
      return id;
    } catch (error) {
      console.log(error.response);
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOnePromotion = createAsyncThunk(
  "promotions/fetchOnePromotion",
  async (id, { rejectWithValue }) => {
    try {
      const response = await PromotionService.getPromotion(id);
      const promotionModified = {
        ...response.data.promotion,
        photo: [response.data.promotion.photo],
      };
      return promotionModified;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  promotions: [],
  promotion: {},
  loading: false,
  error: null,
};

const promotionSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch promotions
      .addCase(getAllPromotions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPromotions.fulfilled, (state, action) => {
        state.loading = false;
        state.promotions = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllPromotions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // fetch promotions by company
      .addCase(getAllPromotionsByCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPromotionsByCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.promotions = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllPromotionsByCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create Promotion
      .addCase(createPromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPromotion.fulfilled, (state, action) => {
        state.promotions.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createPromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update promotion
      .addCase(updatePromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePromotion.fulfilled, (state, action) => {
        const { id, updatedPromotion } = action.payload;
        const index = state.promotions.findIndex(
          (promotion) => promotion.id === id
        );
        if (index !== -1) {
          state.promotions[index] = {
            ...state.promotions[index],
            ...updatedPromotion,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete promotion
      .addCase(deletePromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePromotion.fulfilled, (state, action) => {
        const promotionId = action.payload;
        state.promotions = state.promotions.filter(
          (promotion) => promotion._id !== promotionId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deletePromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOnePromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOnePromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.promotion = action.payload;
        state.error = null;
      })
      .addCase(fetchOnePromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default promotionSlice.reducer;
export const { showLoading, hideLoading, hideError } = promotionSlice.actions;
