import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const InvitationService = {};
InvitationService.getInvitations = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/invitation/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
InvitationService.deleteInvitation = function (invitationId) {
  return fetch({
    method: "delete",
    url: `api/invitation/deleteById/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
InvitationService.getInvitation = function (invitationId) {
  return fetch({
    method: "get",
    url: `api/invitation/getById/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
InvitationService.sendInvitation = function (invitationId) {
  return fetch({
    method: "post",
    url: `api/invitation/send/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default InvitationService;
