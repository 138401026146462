import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ManagersService from "services/ManagersService";

export const getAllManagers = createAsyncThunk(
    "managers/getAllManagers",
    async (page = 1, { rejectWithValue }) => {
        try {
            const response = await ManagersService.getManagers(page);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
export const getAllControllers = createAsyncThunk(
    "managers/getAllControllers",
    async (_, { rejectWithValue }) => {
        try {
            const response = await ManagersService.getAllControllers();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
export const createManager = createAsyncThunk(
    "manager/createManager",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ManagersService.createManager(data);
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);

export const deleteManager = createAsyncThunk(
    "managers/deleteManager",
    async (id, { rejectWithValue }) => {
        try {
            await ManagersService.deleteManager(id);
            return id;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);

export const fetchOneManager = createAsyncThunk(
    "managers/fetchOneManager",
    async (id, { rejectWithValue }) => {
        try {
            const response = await ManagersService.getManager(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
export const fetchOneManagerToUpdate = createAsyncThunk(
    "managers/fetchOneManagerToUpdate",
    async (id, { rejectWithValue }) => {
        try {
            const response = await ManagersService.getManager(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
export const updateManager = createAsyncThunk(
    "managers/updateManager",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await ManagersService.updateManager(id, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
const initialState = {
    controllers: [],
    managers: [],
    manager: {},
    managerToUpdate: {},
    loading: false,
    error: null,
};

const managerSlice = createSlice({
    name: "managers",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state, action) => {
            state.loading = false;
        },
        hideError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch managers
            .addCase(getAllManagers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllManagers.fulfilled, (state, action) => {
                state.loading = false;
                state.managers = action.payload.data;
                state.totalItems = action.payload.totalItems;
            })
            .addCase(getAllManagers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }) // fetch controllers
            .addCase(getAllControllers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllControllers.fulfilled, (state, action) => {
                state.loading = false;
                state.controllers = action.payload;
            })
            .addCase(getAllControllers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Create Manager
            .addCase(createManager.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createManager.fulfilled, (state, action) => {
                state.managers.push(action.payload);
                state.loading = false;
                state.error = null;
            })
            .addCase(createManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete manager
            .addCase(deleteManager.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteManager.fulfilled, (state, action) => {
                const managerId = action.payload;
                state.managers = state.managers.filter(
                    (manager) => manager._id !== managerId
                );
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(fetchOneManager.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOneManager.fulfilled, (state, action) => {
                state.loading = false;
                state.manager = action.payload;
                state.error = null;
            })
            .addCase(fetchOneManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchOneManagerToUpdate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOneManagerToUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.managerToUpdate = action.payload;
                state.error = null;
            })
            .addCase(fetchOneManagerToUpdate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Update service
            .addCase(updateManager.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateManager.fulfilled, (state, action) => {
                const { id, updatedManager } = action.payload;
                const index = state.managers.findIndex(
                    (manager) => manager.id === id
                );
                if (index !== -1) {
                    state.managers[index] = {
                        ...state.managers[index],
                        ...updatedManager,
                    };
                }
                state.loading = false;
                state.error = null;
            })
            .addCase(updateManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export default managerSlice.reducer;
export const { showLoading, hideLoading, hideError } = managerSlice.actions;
